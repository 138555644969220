import { useEffect, createContext, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { login, logout } from "./authService";

export const AuthContext = createContext("Auth");

const AuthProvider = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts, inProgress } = useMsal();

  const [accessToken, setAccessToken] = useState();
  const [error, setError] = useState();

  const accessTokenRequest = {
    scopes: [window._env_.REACT_APP_B2C_LOGIN_SCOPES],
    account: accounts[0],
  };
  useEffect(() => {
    if (isAuthenticated) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((tokenResponse) => {
          setAccessToken(`Bearer ${tokenResponse.accessToken}`);
          sessionStorage.setItem("accesstoken", `Bearer ${tokenResponse.accessToken}`);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          setError(e);
        });
    } // eslint-disable-next-line
  }, [isAuthenticated]);

  if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect && !isAuthenticated) {
    try {
      login(instance, setError);
    } catch (e) {
      setError(e);
    }
  }
  if (accessToken === undefined) {
    setAccessToken(sessionStorage.getItem("accesstoken"));
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: isAuthenticated,
        logout: () => logout(instance),
        accessToken: accessToken,
        error: error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
