/**
 * Create a list of unique origins values
 *
 * @returns {{label: *, value: *}[]}
 */
export const createOriginOptions = () => {
  return [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Veridass",
      value: "VERIDASS",
    },
    {
      label: "POI",
      value: "POI",
    },
    {
      label: "BT",
      value: "BT",
    },
    {
      label: "DIV",
      value: "DIV",
    },
  ];
};
