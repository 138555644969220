import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import PolicySearch from "src/pages/PolicySearch/PolicySearch";
import PolicyDetails from "src/pages/PolicySearch/PolicyDetails";
import File from "src/pages/File";
import Transactions from "src/pages/Transactions";
import InsurerMetrics from "src/pages/InsurerMetrics/InsurerMetrics";
import Div from "src/pages/Div";

const PageRoutes = () => {
  return (
    <Routes>
      <Route exact path={"/"} element={<Navigate to="/policy-search" />} />
      <Route exact path={"/policy-search"} element={<PolicySearch />} />
      <Route path={"/policy/:insureObjectId"} element={<PolicyDetails />} />
      <Route exact path={"/transactions"} element={<Transactions />} />
      <Route exact path={"/insurer-metrics"} element={<InsurerMetrics />} />
      <Route exact path={"/div"} element={<Div />} />
      <Route exact path={"/file"} element={<File />} />
    </Routes>
  );
};

export default PageRoutes;
