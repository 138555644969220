import { Divider as BaseDivider } from "semantic-ui-react";
import styled from "styled-components";

const Divider = styled(BaseDivider)`
  &&&& {
    width: 100%;
    margin-top: 0.5em;
    padding-bottom: 0.5em;
  }
`;

export default Divider;
