import * as yup from "yup";
// eslint-disable-next-line
export default (t) => {
  return yup.object().shape(
    {
      policyNumber: yup.string("Not a string.").min(2, t("transactions.form.validation.min")),
      startDateTime: yup.date().required(),
      endDateTime: yup
        .date()
        .required()
        .when("startDateTime", (eventstartDateTime, schema) => eventstartDateTime && schema.min(eventstartDateTime)),

      failed: yup.bool(),
    },
    [["policyNumber", "startDateTime", "endDateTime", "failed"]]
  );
};
