/**
 * Create a list of unique file status values
 *
 * @returns {{label: *, value: *}[]}
 */

export const createFileStatusOptions = () => {
  return [
    {
      label: "All",
      value: "all",
    },

    {
      label: "Started",
      value: "STARTED",
    },
    {
      label: "Finished",
      value: "FINISHED",
    },
    {
      label: "Failed",
      value: "FAILED",
    },
    {
      label: "Failed_Ack",
      value: "FAILED_ACK",
    },
  ];
};
/**
 * Create a list of unique file type values
 *
 * @returns {{label: *, value: *}[]}
 */
export const createFileTypeOptions = () => {
  return [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Load",
      value: "FILE_LOAD",
    },
    {
      label: "Extract",
      value: "FILE_EXTRACT",
    },
  ];
};
