import { EventType } from "@azure/msal-browser";
import { loginRequest, msalConfig } from "./authConfig";

export const authProvider = async (msalInstance) => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });
};

export const logout = async (instance) => {
  await instance.logoutRedirect({ postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri });
};

export const login = async (msalInstance) => {
  await msalInstance.handleRedirectPromise();

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    msalInstance.loginRedirect({
      loginRequest,
    });
  }
};
