import React from "react";
import styled from "styled-components";
import MenuBar from "./MenuBar";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export default function NavBar() {
  return (
    <Container>
      <div>
        <MenuBar />
      </div>
    </Container>
  );
}
