import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ApiService from "../../../services/ApiService";
import ErrorMessage from "../../../components/common/ui/ErrorMessage/ErrorMessage";
import Spinner from "../../../components/common/ui/Spinner/Spinner";
import Navigation from "../../Navigation";
import { AuthContext } from "../../../auth/AuthProvider";
import PolicyAndHolderTable from "./PolicyAndHolderTable";
import InsuredObjectTable from "./InsuredObjectTable";
import CoverageTable from "./CoverageTable";
import Button from "../../../components/common/ui/Button";
import { Box } from "reflexbox/styled-components";
import { Flex } from "reflexbox/src";
import { Icon } from "semantic-ui-react";
import { prop } from "../../../theme";

const Wrapper = styled.div`
  padding-top: 10px;
`;

const TableWrapper = styled.div`
  margin-top: 4%;
`;

const BackLink = styled(NavLink)`
  display: flex;
  font-size: 1.1rem;
  margin: 1.5rem 0 0.5rem;
  align-items: center;
  text-decoration: none;
`;

const CsvButton = styled(Button)`
  && {
    padding: 12px;
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;
const HeaderFLex = styled(Flex)`
  align-items: baseline;
`;

const BackLinWrapper = styled(Box).attrs({ width: [1] })`
  padding-right: 7px;
  align-items: baseline @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
  && button {
    margin: 30px 20px 0 0;
  }
`;
const CsvButtonWrapper = styled(BackLinWrapper)`
  display: flex;
  justify-content: flex-start;
`;

export default function PolicyDetails() {
  const { t } = useTranslation();
  const { insureObjectId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [insuredObject, setInsuredObject] = useState(true);
  const { accessToken } = useContext(AuthContext);
  const api = new ApiService();
  const location = useLocation();
  const locationState = location.state;
  const redirectUrl = locationState?.url || "/";

  const getInsuredObject = async (insureObjectId) => {
    try {
      const response = await api.getInsuredObject(accessToken, insureObjectId);
      setInsuredObject(response.data);
    } catch (e) {
      console.log(e);
      setError(e.message);
    }
    setLoading(false);
  };
  const getInsuredObjectCsv = async () => {
    try {
      setLoading(true);
      await api.getInsuredObjectCsv(accessToken, insureObjectId);
      setLoading(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInsuredObject(insureObjectId); // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navigation backLink="/policy" />

      <BackLinWrapper>
        <BackLink
          to={{
            pathname: redirectUrl,
            state: locationState,
          }}
        >
          <Icon color={prop("colors.brandy")} name="arrow alternate circle left" /> {t("navigation.actions.back")}
        </BackLink>
      </BackLinWrapper>
      <HeaderFLex>
        <CsvButtonWrapper>
          <CsvButton onClick={getInsuredObjectCsv}>
            <Icon name="external alternate" /> {t("navigation.actions.csv")}
          </CsvButton>
        </CsvButtonWrapper>
      </HeaderFLex>
      {error ? (
        <ErrorMessage message={error} />
      ) : loading ? (
        <Spinner />
      ) : !insuredObject ? (
        <Wrapper>
          <ErrorMessage message={t("policyDetails.noData")} />
        </Wrapper>
      ) : (
        <>
          <TableWrapper>
            <PolicyAndHolderTable insuredObject={insuredObject} />
          </TableWrapper>
          <TableWrapper>
            <InsuredObjectTable insuredObject={insuredObject} />
          </TableWrapper>
          <TableWrapper>
            <CoverageTable insuredObject={insuredObject} />
          </TableWrapper>
        </>
      )}
    </>
  );
}
