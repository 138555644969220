export const colors = {
  black: "#000",
  white: "#fff",
  red: "#d70000",
  extraDarkGrey: "#414141",
  brandy: "#a33033",
  lightBrandy: "#c2393c",
  darkBrandy: "#6f1f22",
  darkGrey: "#666666",
  mediumGrey: "#999999",
  lightGrey: "#cac7c7",
  extraLightGrey: "#e5ded9",
  ultraLightGrey: "#f1edeb",
  lightBlue: "#a4ccea",
  mediumBlue: "#7caad6",
  darkBlue: "#5389c2",
  lightGreen: "#c8cd2e",
  mediumGreen: "#96b439",
  darkGreen: "#5b9853",
  lightPurple: "#c6bfe0",
  mediumPurple: "#9c91c6",
  darkPurple: "#706cb0",
  danger: "#9f3a38",
};

const theme = {
  colors,
  breakpoints: ["400px", "768px", "1024px"],
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  button: {
    color: colors.white,
    background: colors.black,
    hoverColor: colors.white,
    disabledColor: colors.darkGrey,
    disabledBackground: colors.lightGrey,
    disabledBorder: colors.lightGrey,
    primaryColor: colors.white,
    primaryBackground: colors.brandy,
    primaryHover: colors.white,
    primaryHoverBackground: colors.lightBrandy,
    borderColor: colors.black,
    borderHoverColor: colors.white,
    secondaryColor: colors.brandy,
    secondaryBackground: colors.white,
    secondaryHoverBackground: colors.ultraLightGrey,
  },
  input: {
    color: colors.extraDarkGrey,
    background: colors.white,
    border: `1px solid ${colors.lightGrey}`,
    errorBorder: `2px solid ${colors.brandy}`,
    borderRadius: "4px",
    leftBorderRadius: "4px 0 0 4px",
    rightBorderRadius: "0 4px 4px 0",
    width: "100%",
    height: "42px",
    padding: "8px 12px",
    margin: "0 0 0 0",
    fontSize: "1em",
  },
};

function findProperty(target, path) {
  const properties = path.split(".");
  return properties.reduce((object, property, index, array) => {
    return object[property] || (index < array.length - 1 ? {} : undefined);
  }, target);
}

/**
 * Find a theme property or fallback to base theme
 */
export const prop = (path) => (props) => {
  let value;
  if (props.theme) {
    value = findProperty(props.theme, path);
  }
  if (value === undefined) {
    value = findProperty(theme, path);
    if (value === undefined) {
      throw new Error(`Invalid property path '${path}'`);
    }
  }
  return value;
};

export default theme;
