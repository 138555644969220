import React from "react";
import {Icon} from "semantic-ui-react"
import { Box } from "reflexbox/src";

export default function SortableTableHeader({ children, label, sortState, setSortState }) {
  const sort = ()=>{
    if(sortState.label === label){
      if(sortState.order === "desc"){
        setSortState({
          ...sortState,
          order: "asc"
        });
      }else {
        setSortState({
          ...sortState,
          order: "desc"
        });
      }
    }else {
      setSortState({
        ...sortState,
        label: label
      });
    }

  }
  return (
    <div onClick={sort}>
      <Box flexWrap="nowrap">
        {children}
        {sortState.label === label && (
          sortState.order === "desc" ?
            <Icon name={"triangle up"}/>:
            <Icon name={"triangle down"}/>
          )
        }
      </Box>
    </div>
  );
}
