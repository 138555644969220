import * as yup from "yup";
// eslint-disable-next-line
export default (t) => {
  return yup.object().shape({
    licensePlate: yup
      .string("Not a string.")
      .matches(/^[a-zA-Z0-9]+$/, t("policy.form.validations.licensePlate.regex")),
    vin: yup
      .string("Not a string.")
      .min(6, t("policy.form.validations.vin.min"))
      .matches(/^[a-zA-Z0-9]+$/, t("policy.form.validations.vin.regex")),
    policyNumber: yup
      .string("Not a string.")
      .min(2, t("policy.form.validations.policyNumber.min")),
    policyId: yup
      .number()
        .max(2000000000,t("policy.form.validations.policyId.max"))
        .typeError(t("policy.form.validations.policyId.number")),
    insuredObjectId: yup
      .number()
      .max(2000000000,t("policy.form.validations.insuredObjectId.max"))
      .typeError(t("policy.form.validations.insuredObjectId.number")),
    coverageId: yup
      .number()
        .max(2000000000,t("policy.form.validations.coverageId.max"))
        .typeError(t("policy.form.validations.coverageId.number")),
  });
};
