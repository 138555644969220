import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";
import ApiService from "../../services/ApiService";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "../../components/common/ui/Table";
import Title from "../../components/common/ui/Title";

import theme, { prop } from "../../theme";
import ErrorMessage from "../../components/common/ui/ErrorMessage";
import Spinner from "../../components/common/ui/Spinner";

import Navigation from "../Navigation";
import { AuthContext } from "../../auth/AuthProvider";
import moment from "moment";
import Checkbox from "../../components/common/form/Checkbox";

import { Form, Formik, Field } from "formik";
import Fieldset from "../../components/common/form/Fieldset";
import { Box, Flex } from "reflexbox/styled-components";
import SelectField from "../../components/common/form/SelectField";
import InputField from "../../components/common/form/InputField";
import DatePickerField from "../../components/common/form/DatePickerField/DatePickerField";
import Button from "../../components/common/ui/Button";
import searchFormSchema from "./searchFormSchema";
import { addYears } from "date-fns";
import { createOriginOptions } from "./dropdownOptions";
import { Link } from "react-router-dom";
import Pagination from "../../components/common/ui/Pagination";
import SortableTableHeader from "../../components/common/ui/SortableTableHeader";

const SearchButton = styled(Button)`
  && {
    padding: 12px;
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;

const FieldWrapper = styled(Box).attrs({ width: [1, 1, 1 / 3] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
`;

const CsvButtonWrapper = styled(Box).attrs({ width: [1] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
  && button {
    margin: 30px 20px 0 0;
  }
`;
const ButtonWrapper = styled(CsvButtonWrapper)`
  display: flex;
  justify-content: flex-end;
`;

const FieldsHeader = styled.h3`
  font-size: 16px;
  font-weight: 600;

  color: ${prop("colors.lightBrandy")};
`;

const FieldsHeaderContainer = styled(Flex)`
  margin: 15px 0;
  width: 100%;
`;
const OptionalFieldsHeaderContainer = styled(Flex)`
  margin: 15px 0;
  width: 20%;
`;

const NewLine = styled(Flex)`
  width: 100%;
`;

const SearchIcon = styled(Icon).attrs({ name: "search" })``;

const ClearIcon = styled(Icon).attrs({ name: "trash" })``;

const FormWrapper = styled.div`
  margin-bottom: 1rem;
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const iniitialSortValues = {
  label: undefined,
  order: "desc",
};
const iniitialPageValues = {
  number: 1,
  pages: 3,
};
const initialSearchValues = {
  policyNumber: "",
  licensePlate: "",
  vin: "",
  origin: "",
  date: undefined,
  lastName: "",
  postalCode: "",
  policyId: "",
  insuredObjectId: "",
  coverageId: "",
};

export default function PolicySearch() {
  const { t } = useTranslation();
  const location = useLocation();
  const [error, setError] = useState();
  const [searchResp, setSearchResp] = useState();
  const [isSubmitting, setSubmitting] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [showPolicyHolder, setShowPolicyHolder] = useState(false);
  const [showTechId, setShowTechId] = useState(false);

  const [pageState, setPageState] = useState({
    ...iniitialPageValues,
    ...location?.state?.page,
  });
  const [searchValues, setSearchValues] = useState({
    ...initialSearchValues,
    ...location?.state?.values,
  });
  const [sortState, setSortState] = useState({
    ...iniitialSortValues,
    ...location?.state?.sort,
  });

  const minLoadDate = addYears(new Date(), -7);
  const maxLoadDate = addYears(new Date(), 1);
  const { accessToken } = useContext(AuthContext);

  const api = new ApiService();

  const handleSubmit = async (val) => {
    try {
      val.licensePlate = val.licensePlate.toUpperCase().trim();
      val.vin = val.vin.toUpperCase().trim();
      val.policyNumber = val.policyNumber.trim();
      setSearchValues({ ...initialSearchValues, ...val });
      await searchPolicy(val, pageState, sortState);
    } catch (e) {
      console.log(e);
    }
  };
  const clearSearchValues = () => {
    setSearchValues(initialSearchValues);
    setSortState(iniitialSortValues);
    setPageState(1);
    setError(null);
    setSearchResp(undefined);
  };

  const searchPolicy = async (values, page, sort) => {
    try {
      setError(null);
      setSubmitting(true);
      const resp = await api.searchPolicy(accessToken, values, page, sort);
      setSearchResp(resp.data);
      setSubmitting(false);
      setPageState(resp?.page || iniitialPageValues);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };
  const getPolicyCsv = async () => {
    try {
      setSubmitting(true);
      await api.getPolicyCsv(accessToken, searchValues, sortState);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };

  const updatePage = async (page) => {
    const newPageState = {
      ...pageState,
      number: page,
    };
    setPageState(newPageState);
    await searchPolicy(searchValues, newPageState, sortState);
  };
  const updateSort = async (sort) => {
    setSortState(sort);
    await searchPolicy(searchValues, pageState, sort);
  };

  useEffect(() => {
    if (location.state) {
      searchPolicy(searchValues, pageState, sortState);
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navigation />
      <Title>{t("policy.title")}</Title>

      <Formik initialValues={searchValues} onSubmit={handleSubmit} validationSchema={searchFormSchema(t)}>
        {({
          values,
          resetForm,
          isSubmitting,
          setValues,
          /* and other goodies */
        }) => (
          <FormWrapper>
            <Form autoComplete="off">
              <Fieldset>
                <Flex flexWrap="wrap">
                  <FieldsHeaderContainer>
                    <FieldsHeader>{t("policy.headers.policy")}</FieldsHeader>
                  </FieldsHeaderContainer>
                  <FieldWrapper>
                    <InputField
                      name="policyNumber"
                      label={t("policy.form.label.policyNumber")}
                      type="text"
                      value={values.policyNumber}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      name="licensePlate"
                      label={t("policy.form.label.plate")}
                      type="text"
                      value={values.licensePlate?.toUpperCase()?.trim()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <InputField
                      name="vin"
                      label={t("policy.form.label.vin")}
                      type="text"
                      value={values.vin?.toUpperCase()?.trim()}
                    />
                  </FieldWrapper>
                  <Field name="exactMatch" type="checkbox" as={Checkbox} label={t("policy.form.label.exactMatch")} />
                  <NewLine />
                  <OptionalFieldsHeaderContainer onClick={() => setShowDate(!showDate)}>
                    <FieldsHeader>{t("policy.headers.dataOrigin")}</FieldsHeader>
                    <Icon name={showDate ? "triangle up" : "triangle down"} color={theme.colors.lightBrandy} />
                  </OptionalFieldsHeaderContainer>
                  {showDate && (
                    <>
                      <NewLine />
                      <FieldWrapper>
                        <SelectField
                          name="origin"
                          label={t("policy.form.label.origin")}
                          value={values.origin}
                          options={createOriginOptions()}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <DatePickerField
                          name="date"
                          label={t("policy.form.label.date")}
                          minDate={minLoadDate}
                          maxDate={maxLoadDate}
                          locale="en"
                        />
                      </FieldWrapper>
                      <NewLine />
                    </>
                  )}

                  <OptionalFieldsHeaderContainer onClick={() => setShowPolicyHolder(!showPolicyHolder)}>
                    <FieldsHeader>{t("policy.headers.PolicyHolder")}</FieldsHeader>
                    <Icon name={showPolicyHolder ? "triangle up" : "triangle down"} color={theme.colors.lightBrandy} />
                  </OptionalFieldsHeaderContainer>

                  {showPolicyHolder && (
                    <>
                      <NewLine />
                      <FieldWrapper>
                        <InputField
                          name="lastName"
                          label={t("policy.form.label.lastName")}
                          type="text"
                          value={values.lastName?.trim()}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <InputField
                          name="postalCode"
                          label={t("policy.form.label.postalCode")}
                          type="text"
                          value={values.postalCode?.trim()}
                        />
                      </FieldWrapper>
                      <NewLine />
                    </>
                  )}
                  <OptionalFieldsHeaderContainer onClick={() => setShowTechId(!showTechId)}>
                    <FieldsHeader>{t("policy.headers.technicalIdentifiers")}</FieldsHeader>
                    <Icon name={showTechId ? "triangle up" : "triangle down"} color={theme.colors.lightBrandy} />
                  </OptionalFieldsHeaderContainer>
                  {showTechId && (
                    <>
                      <NewLine />
                      <FieldWrapper>
                        <InputField
                          name="policyId"
                          label={t("policy.form.label.policyId")}
                          type="text"
                          value={values.policyId?.toUpperCase()?.trim()}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <InputField
                          name="insuredObjectId"
                          label={t("policy.form.label.insuredObjectId")}
                          type="text"
                          value={values.insuredObjectId?.toUpperCase()?.trim()}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <InputField
                          name="coverageId"
                          label={t("policy.form.label.coverageId")}
                          type="text"
                          value={values.coverageId?.toUpperCase()?.trim()}
                        />
                      </FieldWrapper>
                      <NewLine />
                    </>
                  )}
                </Flex>
                <Flex>
                  <CsvButtonWrapper>
                    {searchResp && searchResp.length !== 0 && (
                      <SearchButton type="button" onClick={getPolicyCsv}>
                        <Icon name="external alternate" /> {t("navigation.actions.csv")}
                      </SearchButton>
                    )}
                  </CsvButtonWrapper>
                  <ButtonWrapper>
                    <SearchButton
                      disabled={isSubmitting}
                      buttonType="secondary"
                      type="button"
                      onClick={() => {
                        clearSearchValues();
                        resetForm(initialSearchValues);
                        setValues(initialSearchValues);
                      }}
                    >
                      <ClearIcon />
                      {t("policy.form.actions.clear")}
                    </SearchButton>
                    <SearchButton disabled={isSubmitting} type="submit" onClick={() => setPageState(1)}>
                      <SearchIcon />
                      {t("policy.form.actions.search")}
                    </SearchButton>
                  </ButtonWrapper>
                </Flex>
              </Fieldset>
            </Form>
          </FormWrapper>
        )}
      </Formik>
      {error ? (
        <ErrorMessage message={error} />
      ) : isSubmitting ? (
        <Spinner />
      ) : (
        searchResp && (
          <>
            <Table>
              <TableHeader>
                <Row>
                  <HeaderCell width="1">{t("policy.table.header.policy")}</HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"licensePlate"} sortState={sortState} setSortState={updateSort}>
                      {t("policy.table.header.plate")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"vin"} sortState={sortState} setSortState={updateSort}>
                      {t("policy.table.header.vin")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"startDate"} sortState={sortState} setSortState={updateSort}>
                      {t("policy.table.header.startDate")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"endDate"} sortState={sortState} setSortState={updateSort}>
                      {t("policy.table.header.endDate")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">{t("policy.table.header.suspension")}</HeaderCell>

                  <HeaderCell width="1">
                    <SortableTableHeader label={"modelName"} sortState={sortState} setSortState={updateSort}>
                      {t("policy.table.header.car")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">
                    <SortableTableHeader label={"lastName"} sortState={sortState} setSortState={updateSort}>
                      {t("policy.table.header.holder")}
                    </SortableTableHeader>
                  </HeaderCell>
                  <HeaderCell width="1">{t("policy.table.header.holderAddress")}</HeaderCell>
                </Row>
              </TableHeader>

              <TableBody>
                {searchResp.length === 0 ? (
                  <Row textAlign={"center"}>
                    <Cell colSpan={11}>
                      <NoData>{t("policy.form.noData")}</NoData>
                    </Cell>
                  </Row>
                ) : (
                  searchResp.map((policy, index) => {
                    return (
                      <Row key={index}>
                        <Cell>{policy?.insurancePolicy?.policyNumber}</Cell>
                        <Cell>
                          <Link
                            to={{
                              pathname: `/policy/${policy?.insuredObject?.identifier}`,
                              state: {
                                url: "/policy",
                                values: searchValues,
                                page: pageState,
                                sort: sortState,
                              },
                            }}
                          >
                            {policy?.insuredObject?.licensePlate}
                          </Link>
                        </Cell>
                        <Cell>
                          <Link
                            to={{
                              pathname: `/policy/${policy?.insuredObject?.identifier}`,
                              state: {
                                url: "/policy",
                                values: searchValues,
                                page: pageState,
                                sort: sortState,
                              },
                            }}
                          >
                            {policy?.insuredObject?.vin}
                          </Link>
                        </Cell>
                        <Cell>{moment(policy?.coverage?.startDate)?.format("DD/MM/YYYY")}</Cell>
                        <Cell>
                          {policy?.coverage?.endDate && moment(policy?.coverage?.endDate)?.format("DD/MM/YYYY")}
                        </Cell>
                        <Cell>{policy?.coverage?.suspensionType}</Cell>
                        <Cell>
                          {policy?.insuredObject?.makeName} {policy?.insuredObject?.modelName}
                        </Cell>
                        <Cell>
                          {policy?.insuranceHolder?.firstName} {policy?.insuranceHolder?.lastName}
                        </Cell>
                        <Cell>
                          {policy?.insuranceHolder?.streetName} {policy?.insuranceHolder?.houseNumber}{" "}
                          {policy?.insuranceHolder?.cityName} {policy?.insuranceHolder?.postalCode}{" "}
                          {policy?.insuranceHolder?.countryCode}
                        </Cell>
                      </Row>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <Pagination onChange={(page) => updatePage(page)} number={pageState?.number} pages={pageState?.pages} />
          </>
        )
      )}
    </>
  );
}
