import * as yup from "yup";
// eslint-disable-next-line
export default (t) => {
  return yup.object().shape(
    {
      source: yup.string("Not a string."),
      startDateTime: yup.date().required(),
      endDateTime: yup
        .date()
        .required()
        .when(
          "startDateTime",
          (eventstartDateTime, schema) =>
            eventstartDateTime && schema.min(eventstartDateTime)
        ),

      failed: yup.bool(),
    },
    [["source", "startDateTime", "endDateTime", "failed"]]
  );
};
