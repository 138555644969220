import React from "react";
import styled from "styled-components";
import { Loader } from "semantic-ui-react";

import { prop } from "../../../../theme";

const StyledLoader = styled(Loader).attrs({
  active: true,
  inline: "centered",
})`
  && {
    &::after {
      border-color: ${prop("colors.brandy")} transparent transparent;
    }
  }
`;

export default function Spinner({ size }) {
  return <StyledLoader size={size} />;
}
