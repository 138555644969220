import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "semantic-ui-css/semantic.min.css";

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { authProvider } from "./auth/authService";
import { msalConfig } from "./auth/authConfig";
import AuthProvider from "./auth/AuthProvider";

import App from "./App";

export const msalInstance = new PublicClientApplication(msalConfig);

authProvider(msalInstance);

function renderRoot() {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <AuthProvider instance={msalInstance}>
          <App />
        </AuthProvider>
      </MsalProvider>
    </BrowserRouter>
  );
}
if (window._env_.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./App", renderRoot);
}

window.addEventListener("load", renderRoot);
