import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Box, Flex } from "reflexbox/styled-components";
import logo from "src/components/assets/images/logo.png";
import NavBar from "./NavBar";
import { prop } from "../../theme";
import Button from "src//components/common/ui/Button";
import { AuthContext } from "../../auth/AuthProvider";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

const Logo = styled.div`
  margin-top: 20px;
  margin-bottom: 1rem;
  align-items: flex-start;
  color: ${prop("colors.brandy")};
  display: flex;
`;

const LogoutButton = styled(Button)`
  padding-right: 1rem;
  && {
    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;
const LogoImg = styled.img`
  height: 50px;
  margin-top: 15px;
  margin-right: 10px;
`;
// eslint-disable-next-line
export default function ({ backLink }) {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);

  return (
    <>
      <Flex flexWrap="wrap" alignContent={"space-between"}>
        <Box>
          <Wrapper>
            <Link to="/" style={{ textDecoration: "none" }}>
              <Logo>
                <LogoImg src={logo} />
                <h1> POI REPORTING</h1>
              </Logo>
            </Link>
          </Wrapper>
        </Box>
        <Box ml={"auto"}>
          <LogoutButton
            type="submit"
            onClick={() => {
              logout();
            }}
          >
            {t("navigation.actions.logout")}
          </LogoutButton>
        </Box>
      </Flex>
      {!backLink && <NavBar />}
    </>
  );
}
