import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import lodash from "lodash";
import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "../../components/common/ui/Table";
import { prop } from "../../theme";
import Subtitle from "../../components/common/ui/Subtitle";

const NoData = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 1.2rem;
  color: ${prop("colors.lightGrey")};
  font-weight: bold;
  letter-spacing: 1.2px;
`;

const TransactionCounts = ({ transactionCounts }) => {
  const { t } = useTranslation();

  return (
    <>
      <Subtitle>{t("insurerMetrics.table.transaction.title")}</Subtitle>
      <Table>
        <TableHeader>
          <Row>
            <HeaderCell width="1">{t("insurerMetrics.table.transaction.header.operation")}</HeaderCell>
            <HeaderCell width="1">{t("insurerMetrics.table.transaction.header.httpVerb")}</HeaderCell>
            <HeaderCell width="1">{t("insurerMetrics.table.transaction.header.transactions")}</HeaderCell>
          </Row>
        </TableHeader>

        <TableBody>
          {transactionCounts.length === 0 ? (
            <Row textAlign={"center"}>
              <Cell colSpan={11}>
                <NoData>{t("insurerMetrics.form.noData")}</NoData>
              </Cell>
            </Row>
          ) : (
            transactionCounts?.map((transaction, index) => {
              return (
                <>
                  <Row key={index}>
                    <Cell>
                      {!transaction.endpoint && !transaction.operation
                        ? t("insurerMetrics.table.transaction.otherTransactions")
                        : transaction.endpoint
                        ? lodash.truncate(transaction.endpoint, { length: 60 })
                        : lodash.truncate(transaction.operation, { length: 60 })}
                    </Cell>
                    <Cell>{transaction.verb ? transaction.verb : transaction.source}</Cell>
                    <Cell textAlign={"right"}>{transaction.transactions}</Cell>
                  </Row>
                </>
              );
            })
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default TransactionCounts;
