import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Title from "../../components/common/ui/Title";
import Subtitle from "../../components/common/ui/Subtitle";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Icon } from "semantic-ui-react";
import ApiService from "../../services/ApiService";

import { prop } from "../../theme";
import ErrorMessage from "../../components/common/ui/ErrorMessage";
import Spinner from "../../components/common/ui/Spinner";

import Navigation from "../Navigation";
import { AuthContext } from "../../auth/AuthProvider";
import { createSourceOptions } from "./dropdownOptions";

import { Form, Formik } from "formik";
import Fieldset from "../../components/common/form/Fieldset";
import { Box, Flex } from "reflexbox/styled-components";
import SelectField from "../../components/common/form/SelectField";
import DatePickerField from "../../components/common/form/DatePickerField/DatePickerField";
import Button from "../../components/common/ui/Button";
import { addYears } from "date-fns";
import { Link } from "react-router-dom";
import insurerMetricsSchema from "./insurerMetricsSchema";
import FailureCounts from "./FailureCounts";
import TransactionCounts from "./TransactionCounts";
import DailyCounts from "./DailyCounts";

const StyledCard = styled(Card).attrs({ color: "red" })`
  margin: 0 1em !important;
  a {
    text-decoration: none;
    color: ${prop("colors.black")};
    font-size: inherit;
    font-weight: inherit;
  }
`;

const CardsWrapper = styled.div`
  padding: 1em;
  display: flex;
`;
const SearchButton = styled(Button)`
  && {
    padding: 12px;
    margin-top: 10px;

    @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
      margin-top: 27px;
      margin-left: 5px;
    }
  }
`;

const CsvButtonWrapper = styled(Box).attrs({ width: [1] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
  && button {
    margin: 30px 20px 0 0;
  }
`;
const ButtonWrapper = styled(CsvButtonWrapper)`
  display: flex;
  justify-content: flex-end;
`;
const SearchIcon = styled(Icon).attrs({ name: "search" })``;

const ClearIcon = styled(Icon).attrs({ name: "trash" })``;

const FormWrapper = styled.div`
  margin-bottom: 1rem;
`;
const FieldWrapper = styled(Box).attrs({ width: [1, 1, 1 / 3] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
`;
const initialSearchValues = {
  source: "",
  startDateTime: new Date(new Date().setHours(0, 0, 0, 0)),
  endDateTime: new Date(new Date().setHours(23, 59, 59, 999)),
};
const InsurerMetrics = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const minLoadDate = addYears(new Date(), -window._env_?.REACT_APP_TRANSACTIONS_MAX_QUERY_YEARS_PAST);
  const maxLoadDate = addYears(new Date(), window._env_?.REACT_APP_TRANSACTIONS_MAX_QUERY_YEARS_FUTUR);
  const { accessToken } = useContext(AuthContext);

  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [keyMetrics, setKeyMetrics] = useState();
  const [dailyCounts, setDailyCounts] = useState();
  const [transactionCounts, setTransactionCounts] = useState();
  const [failureCounts, setFailureCounts] = useState();

  const [searchValues, setSearchValues] = useState({
    ...initialSearchValues,
    ...location?.state?.values,
  });
  const api = new ApiService();

  const handleSubmit = async (values) => {
    try {
      setSearchValues({ ...initialSearchValues, ...values });
      await fetchKeyMetrics(values);
      await fetchDailyCounts(values);
      await fetchTransactionCounts(values);
      await fetchFailureCounts(values);
    } catch (e) {
      console.log(e);
    }
  };
  const fetchKeyMetrics = async (values) => {
    try {
      setError(null);
      setSubmitting(true);
      const resp = await api.getKeyMetrics(accessToken, values);
      setKeyMetrics(resp.data);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };
  const fetchDailyCounts = async (values) => {
    try {
      setError(null);
      setSubmitting(true);
      const resp = await api.getDailyCounts(accessToken, values);
      setDailyCounts(resp.data);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };
  const fetchTransactionCounts = async (values) => {
    try {
      setError(null);
      setSubmitting(true);
      const resp = await api.getTransactionCounts(accessToken, values);
      setTransactionCounts(resp.data);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };
  const fetchFailureCounts = async (values) => {
    try {
      setError(null);
      setSubmitting(true);
      const resp = await api.getFailureCounts(accessToken, values);
      setFailureCounts(resp.data);
      setSubmitting(false);
    } catch (e) {
      console.log(e);
      setError(e);
      setSubmitting(false);
    }
  };

  const clearSearchValues = () => {
    setSearchValues(initialSearchValues);
    setError(null);
    setKeyMetrics(undefined);
    setDailyCounts(undefined);
    setFailureCounts(undefined);
    setTransactionCounts(undefined);
  };
  useEffect(() => {
    if (location.state) {
      fetchKeyMetrics(searchValues);
      fetchDailyCounts(searchValues);
      fetchTransactionCounts(searchValues);
      fetchFailureCounts(searchValues);
    } // eslint-disable-next-line
  }, []);

  return (
    <>
      <Navigation />
      <Title>{t("insurerMetrics.title")}</Title>
      <Formik initialValues={searchValues} onSubmit={handleSubmit} validationShema={insurerMetricsSchema(t)}>
        {({ values, resetForm, isSubmitting, setValues }) => (
          <FormWrapper>
            <Form autoComplete="off">
              <Fieldset>
                <Flex flexWrap="wrap">
                  <FieldWrapper>
                    <SelectField
                      name="source"
                      label={t("insurerMetrics.form.label.source")}
                      value={values.source}
                      options={createSourceOptions()}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="startDateTime"
                      label={t("insurerMetrics.form.label.startDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <DatePickerField
                      name="endDateTime"
                      label={t("insurerMetrics.form.label.endDateTime")}
                      minDate={minLoadDate}
                      maxDate={maxLoadDate}
                      locale="en"
                    />
                  </FieldWrapper>
                </Flex>
                <Flex>
                  <ButtonWrapper>
                    <SearchButton
                      disabled={isSubmitting}
                      buttonType="secondary"
                      type="button"
                      onClick={() => {
                        clearSearchValues();
                        resetForm(initialSearchValues);
                        setValues(initialSearchValues);
                      }}
                    >
                      <ClearIcon />
                      {t("insurerMetrics.form.actions.clear")}
                    </SearchButton>
                    <SearchButton disabled={isSubmitting} type="submit">
                      <SearchIcon />
                      {t("insurerMetrics.form.actions.search")}
                    </SearchButton>
                  </ButtonWrapper>
                </Flex>
              </Fieldset>
            </Form>
          </FormWrapper>
        )}
      </Formik>
      {error ? (
        <ErrorMessage message={error} />
      ) : isSubmitting ? (
        <Spinner />
      ) : (
        <>
          {keyMetrics && (
            <>
              <Subtitle>{t("insurerMetrics.keyMetrics.title")}</Subtitle>
              <CardsWrapper>
                <StyledCard>
                  <Card.Content>
                    <Card.Header>{t("insurerMetrics.keyMetrics.transaction")}</Card.Header>
                    <Link
                      to={{
                        pathname: `/transactions`,
                        state: {
                          values: {
                            url: "/insurer-metrics",
                            endDateTime: searchValues.endDateTime,
                            startDateTime: searchValues.startDateTime,
                            failed: searchValues.failed,
                          },
                        },
                      }}
                    >
                      <Card.Description>{keyMetrics?.transactions}</Card.Description>
                    </Link>
                  </Card.Content>
                </StyledCard>
                <StyledCard>
                  <Card.Content>
                    <Card.Header>{t("insurerMetrics.keyMetrics.average")}</Card.Header>
                    <Card.Description>{keyMetrics?.averageDelay}</Card.Description>
                  </Card.Content>
                </StyledCard>
                <StyledCard>
                  <Card.Content>
                    <Card.Header>{t("insurerMetrics.keyMetrics.failures")}</Card.Header>
                    <Card.Description>
                      <Link
                        to={{
                          pathname: `/transactions`,
                          state: {
                            values: {
                              url: "/insurer-metrics",
                              endDateTime: searchValues.endDateTime,
                              startDateTime: searchValues.startDateTime,
                              failed: true,
                            },
                          },
                        }}
                      >
                        {keyMetrics?.failures} ({keyMetrics.failuresPercentage}
                        %)
                      </Link>
                    </Card.Description>
                  </Card.Content>
                </StyledCard>
              </CardsWrapper>
            </>
          )}
          {dailyCounts && <DailyCounts counts={dailyCounts} />}
          {transactionCounts && <TransactionCounts transactionCounts={transactionCounts} />}
          {failureCounts && <FailureCounts failureCounts={failureCounts} />}
        </>
      )}
    </>
  );
};

export default InsurerMetrics;
