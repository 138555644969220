import React, { useRef } from "react";
import PropTypes from "prop-types";
import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from "react-input-mask";
import { withTranslation } from "react-i18next";
import { useField } from "formik";
import { isValid } from "date-fns";
import styled, { createGlobalStyle } from "styled-components";
import en from "date-fns/locale/en-GB";
import fr from "date-fns/locale/fr";
import nl from "date-fns/locale/nl-BE";

import "react-datepicker/dist/react-datepicker.css";

import Field, { propTypes as fieldPropTypes } from "../Field";
import Input, { propTypes as inputPropTypes } from "../Input";
import { prop } from "src/theme";

import closeIconSrc from "./close.svg";

registerLocale("en", en);
registerLocale("fr", fr);
registerLocale("nl", nl);

const StyledInputMask = styled(InputMask)`
  font-size: 1.2rem;
`;

const GlobalStyle = createGlobalStyle`
  body {
    .react-datepicker__header {
      background: ${prop("colors.white")};
      border-bottom: 1px solid ${prop("colors.lightBrandy")};
      border-radius: 0;
    }

    .react-datepicker {
      border: 1px solid ${prop("colors.extraDarkGrey")};
      border-radius: 5px;
      padding: 15px;
    }
    .react-datepicker__day {
      margin: 5px;
      font-size: 15px;
      width: 32px;
      height: 32px;
      line-height: 31px;
      color: ${prop("colors.extraDarkGrey")};
      user-select: none;
    }
    .react-datepicker__day-name {
      font-size: 14px;
      color: ${prop("colors.mediumGrey")};
      height: fit-content;
      width: 32px;
      line-height: normal;
      margin: 10px 5px 0
    }
    .react-datepicker__day--selected {
      color: ${prop("colors.brandy")};
      background-color: transparent;
      border-radius: 5px;
    }
    .react-datepicker__day--selected:hover {
      color: ${prop("colors.black")};
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
      border-bottom-color: ${prop("colors.white")};
      margin-top: -5px;
    }
    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
      border-bottom-color: ${prop("colors.extraDarkGrey")};
      top: -1px;
    }
    .react-datepicker__day:hover, .react-datepicker__month-text:hover {
      background-color: ${prop("colors.extraLightGrey")};
      border-radius: 5px;
    }
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      width: 100%;
    }
    .react-datepicker__day--outside-month {
      visibility: hidden;
    }
    .react-datepicker__day--disabled {
      color: ${prop("colors.lightGrey")}
    }
    .react-datepicker__day--disabled:hover {
      background-color: transparent;
    }

 .react-datepicker__year-select{
  width: 7rem;
  margin-left: 10px;
      border: 1px solid ${prop("colors.lightGrey")};
      border-radius: 4px;
      height: 2.2rem;
      font-size: 1.1rem;
      outline: none;
      color: ${prop("colors.extraDarkGrey")};
      padding-left: 5px;
    }    
    .react-datepicker__month-select{
      border: 1px solid ${prop("colors.lightGrey")};
      border-radius: 4px;
      height: 2.2rem;
      font-size: 1.1rem;
      width: 10rem;
      outline: none;
      color: ${prop("colors.extraDarkGrey")};
      padding-left: 5px;
      
    }  
    
    .react-datepicker__header__dropdown{
      display: flex;
      justify-content: space-between;
      margin: 1rem;
    
    }

    .react-datepicker__close-icon::after {
      cursor: pointer;
      background-color: transparent;
      font-size: 12px;
      content: " ";
      height: 24px;
      width: 24px;
      background-image: url(${closeIconSrc});
      background-repeat: no-repeat;
    }
    .react-datepicker__close-icon {
      top: 3px;    
    }
  }
`;

const propTypes = {
  ...fieldPropTypes,
  ...inputPropTypes,
  value: PropTypes.objectOf(),
  onChange: PropTypes.func,
  locale: PropTypes.oneOf(["en", "fr", "nl"]),
  dateFormat: PropTypes.string,
  validate: PropTypes.func,
  minDate: PropTypes.objectOf(Date).isRequired,
  maxDate: PropTypes.objectOf(Date).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }),
};
const defaultProps = {
  dateFormat: "dd/MM/yyyy",
};

const DatePickerField = (props) => {
  const { label, id, required, explanation, hint, locale, dateFormat, i18n, ...restProps } = props;

  const [field, meta, helpers] = useField(props);

  const { value } = field;
  const error = meta.touched && meta.error;
  let datePickerRef = useRef(null);

  return (
    <>
      <GlobalStyle />

      <Field label={label} id={id} required={required} explanation={explanation} hint={hint} error={error}>
        <DatePicker
          ref={datePickerRef}
          {...field}
          {...restProps}
          selected={(value && new Date(value)) || null}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          onChange={(date) => {
            if (date) {
              const dateInstance = new Date(date);
              if (isValid(dateInstance)) {
                helpers.setValue(dateInstance);
              }
            } else {
              // If we empty the date field, just return an empty string
              helpers.setValue("");
            }
          }}
          customInput={
            <StyledInputMask mask={dateFormat.replace(/[dMyHm]/g, 9)} error={error}>
              {(inputProps) => <Input {...props} {...inputProps} />}
            </StyledInputMask>
          }
          dateFormat={dateFormat}
          locale={locale || i18n.language}
          disabledKeyboardNavigation
        />
      </Field>
    </>
  );
};

DatePickerField.propTypes = propTypes;
DatePickerField.defaultProps = defaultProps;
export default withTranslation()(DatePickerField);
