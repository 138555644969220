import Table, { Cell, HeaderCell, Row, TableBody, TableHeader } from "../../../components/common/ui/Table/Table";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment from "moment";

const TableSubHeader = styled.div`
  font-weight: bold;
`;
const TitleCell = styled(HeaderCell)`
  font-size: large;
`;

export default function CoverageTable({ insuredObject }) {
  const { t } = useTranslation();
  return (
    <Table>
      <TableHeader>
        <Row>
          <TitleCell colSpan="7" textAlign={"center"}>
            {t("policyDetails.table.Coverages.title")}
          </TitleCell>
        </Row>
      </TableHeader>
      <TableBody>
        <Row key={0}>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.Coverages.subHeaders.startDate")}</TableSubHeader>
          </Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.Coverages.subHeaders.endDate")}</TableSubHeader>
          </Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.Coverages.subHeaders.suspensionType")}</TableSubHeader>
          </Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.Coverages.subHeaders.coverageNr")}</TableSubHeader>
          </Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.Coverages.subHeaders.transactionTimestamp")}</TableSubHeader>
          </Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.Coverages.subHeaders.veridassId")}</TableSubHeader>
          </Cell>
          <Cell>
            <TableSubHeader>{t("policyDetails.table.Coverages.subHeaders.poiId")}</TableSubHeader>
          </Cell>
        </Row>
        {insuredObject?.insuredObject?.coverages.map((coverage, index) => (
          <Row key={index + 1}>
            <Cell>{moment(coverage.startDate)?.format("DD/MM/YYYY")}</Cell>
            <Cell>{coverage.endDate && moment(coverage.endDate)?.format("DD/MM/YYYY")}</Cell>
            <Cell>{coverage.suspensionType}</Cell>
            <Cell>{coverage.coverageNumber}</Cell>
            <Cell>{coverage.transactionTimestamp}</Cell>
            <Cell>{coverage.veridassIdentifier}</Cell>
            <Cell>{coverage.identifier}</Cell>
          </Row>
        ))}
      </TableBody>
    </Table>
  );
}
