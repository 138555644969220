import { default as BaseSelect } from "react-select";
import { colors } from "src/theme";

const SelectInput = (props) => {
  const getOptionBackgroundColor = (state) => {
    if (state.selectProps.isDisabled) {
      return colors.lightGrey;
    } else {
      return state.selectProps.isFocused ? colors.extraDarkGrey : "white";
    }
  };

  const getControlBackgroundColor = (state) => {
    if (state.isDisabled) {
      return colors.lightGrey;
    } else {
      if (state.menuIsOpen) {
        return "white";
      } else {
        return props.variant === "dark" ? colors.brandy : "white";
      }
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: colors.extraDarkGrey,
      borderColor: colors.lightGrey,
      backgroundColor: getOptionBackgroundColor(state),
      ":active": { backgroundColor: colors.lightGrey },
      ":hover": { backgroundColor: colors.brandy, color: "white", fontWeight: "600" },
      ":after": {
        content: state.isSelected ? `url(/img/selected.svg)` : undefined,
        marginLeft: "15px",
      },
      padding: "8px 20px",
      whiteSpace: props.noWrap ? "nowrap" : "normal",
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: getControlBackgroundColor(state),
      borderBottomLeftRadius: state.menuIsOpen ? 0 : undefined,
      borderBottomRightRadius: state.menuIsOpen ? 0 : undefined,
      boxShadow: "none",
      borderColor: colors.lightGrey,
      "&:hover": {
        borderBottomColor: state.menuIsOpen ? "white" : undefined,
      },
      width: "100%",
      padding: "2px",
      ...props.controlStyles,
    }),
    indicatorSeparator: (provided) => ({ ...provided, width: 0 }),
    placeholder: (provided) => ({
      ...provided,
      color: props.variant === "dark" ? "white" : undefined,
    }),
    singleValue: (provided) => ({
      ...provided,
      color: props.variant === "dark" ? "white" : undefined,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: colors.brandy,
    }),
    menu: (provided) => ({
      ...provided,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      zIndex: 100,
      width: "calc(100% - 2px)",
      left: "1px",
    }),
  };
  return (
    <BaseSelect
      {...props}
      styles={customStyles}
      ref={props.innerRef}
      theme={(provided) => ({
        ...provided,
        spacing: {
          ...provided.spacing,
          menuGutter: 0,
        },
      })}
    />
  );
};

export default SelectInput;
