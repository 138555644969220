import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field as FormikField, getIn } from "formik";

import Field, { propTypes as fieldPropTypes } from "../Field";
import Input, { propTypes as inputPropTypes } from "../Input";

export default class InputField extends Component {
  static propTypes = {
    ...fieldPropTypes,
    ...inputPropTypes,
    validate: PropTypes.func,
  };
  render() {
    const { name, label, id, required, explanation, type, validate, ...props } = this.props;
    return (
      <FormikField name={name} validate={validate}>
        {({ field, form }) => {
          const error = getIn(form.touched, field.name) && getIn(form.errors, field.name);
          return (
            <Field
              label={label}
              id={id}
              required={required}
              explanation={explanation}
              error={error}
              type={type}
              disabled={props.disabled}
            >
              <Input type={type} id={id} {...field} {...props} error={!!error} />
            </Field>
          );
        }}
      </FormikField>
    );
  }
}
