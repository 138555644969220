import { createGlobalStyle } from "styled-components";
import { prop } from "./theme";

export default createGlobalStyle`

  @font-face {
      font-family: NN Dagny;
      src:  url('./assets/fonts/NNDagnyTextWeb.woff') format('woff');
      font-weight: 400;
      font-style: normal
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
  body {
    color: ${prop("colors.extraDarkGrey")};
    font-family: NN Dagny,Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.4285em;  
  }
  button {
    font-family: NN Dagny,Helvetica,Arial,sans-serif;
  }

  a {
    color: ${prop("colors.darkGrey")};
    text-decoration: underline;
    :hover {
      color: ${prop("colors.brandy")};
      text-decoration: underline;
    }
  }
  body .react-datepicker__close-icon {
    top: 0 !important;
  }
`;
