import { useField, useFormikContext } from "formik";
import { get } from "lodash";

import { colors } from "src/theme";
import SelectInput from "./SelectInput";
import Field from "../Field";

const SelectField = (props) => {
  const [field, meta, { setValue, setTouched }] = useField(props);
  const { touched } = useFormikContext();

  const isTouched = get(touched, field.name);

  const error = (meta.touched || isTouched) && meta.error;

  const onChange = (data) => {
    if (props.isMulti) {
      setValue(data);
    } else {
      setValue(data?.value);
    }
  };

  const populateValues = () => {
    if (props.isMulti) {
      return field.value;
    } else {
      return props.options ? props.options.find((option) => option.value === field.value) : "";
    }
  };

  return (
    <Field
      label={props.label}
      id={props.id}
      required={props.required}
      explanation={props.explanation}
      error={error}
      type={props.type}
      disabled={props.disabled}
    >
      <SelectInput
        data-testid="selectField"
        inputId="selectField"
        {...field}
        {...props}
        // ref={props.innerRef ?? undefined}
        value={populateValues()}
        onChange={(data) => {
          onChange(data);
          props.onChange && props.onChange(data);
        }}
        onBlur={() => setTouched(true)}
        controlStyles={
          error && {
            borderColor: colors.red[500],
            boxShadow: `0 0 0 1px ${colors.red[500]}`,
          }
        }
      />
    </Field>
  );
};

export default SelectField;
