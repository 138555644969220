import { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Menu } from "semantic-ui-react";
import { prop } from "../../theme";
import { useNavigate } from "react-router-dom";

const StyledMenu = styled(Menu).attrs({
  menu: { secondary: true, pointing: true },
})`
  .ui.segment {
    border: 0;
    box-shadow: none;
  }
  .tab {
    padding: 10px 0;
  }

  &&&&& {
    a {
      font-size: 1.3rem;
      font-weight: bold;
      color: ${prop("colors.darkGrey")};
    }
    a.active,
    a.active:hover {
      color: ${prop("colors.lightBrandy")};
      border-top: 3px solid ${prop("colors.lightBrandy")};
      border-bottom: 2px solid white;
    }
    a.item:hover {
      color: ${prop("colors.lightBrandy")};
    }
    a.item {
      padding: 12px 12px;
    }
  }
`;

export default function MenuBar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = location.pathname.split("/")[1] || "home";
  const [activeItem, setActiveItem] = useState(initialState);

  const handleItemClick = (e, { name }) => {
    setActiveItem(name);
    navigate(name === "policy-search" ? `/policy-search` : `/${name}`);
  };

  return (
    <StyledMenu pointing secondary>
      <Menu.Item name="policy-search" active={activeItem === "policy-search"} onClick={handleItemClick}>
        {t("tabs.titles.policy")}
      </Menu.Item>
      <Menu.Item name="transactions" active={activeItem === "transactions"} onClick={handleItemClick}>
        {t("tabs.titles.transactions")}
      </Menu.Item>
      <Menu.Item name="insurer-metrics" active={activeItem === "insurer-metrics"} onClick={handleItemClick}>
        {t("tabs.titles.insurerMetrics")}
      </Menu.Item>
      <Menu.Item name="file" active={activeItem === "file"} onClick={handleItemClick}>
        {t("tabs.titles.file")}
      </Menu.Item>
      <Menu.Item name="div" active={activeItem === "div"} onClick={handleItemClick}>
        {t("tabs.titles.div")}
      </Menu.Item>
    </StyledMenu>
  );
}
