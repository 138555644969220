import * as yup from "yup";
// eslint-disable-next-line
export default (t) => {
  return yup.object().shape(
    {
      startDateTime: yup.date(),
      endDateTime: yup
        .date()
        .when("startDateTime", (eventstartDateTime, schema) => eventstartDateTime && schema.min(eventstartDateTime)),
    },
    [["startDateTime", "endDateTime"]]
  );
};
