import React from "react";
import styled from "styled-components";
import { Box } from "reflexbox/styled-components";

const FieldWrapperStyled = styled(Box).attrs({ width: [1, 1 / 2] })`
  padding-right: 7px;
  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    input {
      margin-right: 0px;
    }
  }
`;

export default function FieldWrapper({ children }) {
  return <FieldWrapperStyled>{children}</FieldWrapperStyled>;
}
