import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  @media (min-width: 1200px) {
    width: 1200px;
  }
  max-width: 100%;
  margin: 0 auto;
  padding: 1rem 2rem 2rem;
`;

export default Container;
