import React, { useContext, useState, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import "./i18n";
import theme from "./theme";
import GlobalStyle from "./GlobalStyle";
import Container from "src/components/Container";
import { AuthContext } from "./auth/AuthProvider";

import PageRoutes from "./pages/routes";
import Spinner from "src/components/common/ui/Spinner";
import ErrorMessage from "src/components/common/ui/ErrorMessage";

const Wrapper = styled.div`
  padding: 50px;
`;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Main = styled.div`
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
`;

const App = () => {
  const { isAuthenticated, error } = useContext(AuthContext);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      setTimeout(() => {
        setShowError(true);
      }, window._env_.REACT_APP_SPINNER_TIMEOUT * 1000);
    }
  }, [isAuthenticated]);

  if (error && isAuthenticated) {
    return (
      <Wrapper>
        <ErrorMessage message={error} />
      </Wrapper>
    );
  }

  if (!isAuthenticated) {
    return <Wrapper>{showError ? <ErrorMessage /> : <Spinner />}</Wrapper>;
  }

  if (error) {
    return (
      <Wrapper>
        <ErrorMessage message={error} />
      </Wrapper>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Main>
          <Container>
            <PageRoutes />
          </Container>
        </Main>
      </ThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
};

export default App;
